import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconChevronLeft16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M10.0631 13.9099L9.56712 13.416L7.05284 10.9121L4.53855 8.40825L4.04041 7.91218L4.53862 7.41618L7.04588 4.92003L9.55313 2.42388L10.0492 1.93001L11.037 2.92215L10.5409 3.41603L8.03363 5.91218L6.02458 7.91232L8.04073 9.9201L10.555 12.424L11.051 12.9179L10.0631 13.9099Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconChevronLeft16);
export default ForwardRef;