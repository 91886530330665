import React, { forwardRef, ReactNode, Ref } from 'react';
import NukaCarousel, { ControlProps } from '@stitch-fix/nuka-carousel';
import {
  getResponsiveCssValue,
  getResponsiveValue,
  ResponsiveValue,
  ResponsiveSizeValue,
} from '../_internal/styling';
import useScreenSize from '../_internal/useScreenSize';
import useMedia from '../_internal/useMedia';
import Box from '../Box';
import IconButton from '../IconButton';
import { useTheme } from '../ThemeProvider';
import {
  IconChevronLeft16,
  IconChevronLeft24,
  IconChevronRight16,
  IconChevronRight24,
} from '../icons';
import styles from './carousel.module.scss';

export interface CarouselProps {
  /**
   * Used to label the Carousel region. Required to be unique if there are multiple carousels on a page.
   */
  ariaLabel: string;
  /**
   * The position of the control buttons (prev, next) from the top of the carousel.
   *
   * Useful when aligning controls with imagery, eg. SkuCards
   *
   * @default '50%'
   */
  controlsOffsetTop?: ResponsiveValue<string | number>;
  /**
   * Carousel Slides
   */
  children: ReactNode;
  /**
   * Extends the sides of the carousel beyond its containing box by the specified amount. Useful for
   * allowing the carousel to have a full-bleed width, particularly on small devices. The value
   * specified should match the gutter size of its container.
   *
   * E.g. `{ sm: 1, lg: 0 }`
   */
  gutterOverflow?: ResponsiveSizeValue;
  /**
   * Sets the width of the carousel elements.
   * Accepts any value that can be used as a width in CSS.
   * Value can be responsive, e.g., `{ sm: "300px", md: "500px" }`.
   */
  slideWidth: ResponsiveSizeValue;
  /**
   * Used to disable navigation controls and swiping. Useful if the Carousel is rendering a loading state.
   */
  withoutControls?: boolean;
}

/**
 * Carousels are used to display serial content that is related by either type or theme.
 *For example, a carousel may be used for displaying multiple SKU cards or images, video,
 * and outfits for a specific SKU on the PDP.
 */
const Carousel = forwardRef(
  (
    {
      ariaLabel,
      controlsOffsetTop,
      children,
      gutterOverflow,
      slideWidth,
      withoutControls = false,
    }: CarouselProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const isRebranded = useTheme() === 'brand-2024-full';
    const screenSize = useScreenSize();
    const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
    const medScreenOrSmaller = screenSize === 'sm' || screenSize === 'md';
    const responsiveOffsetTop = getResponsiveCssValue(
      screenSize,
      controlsOffsetTop,
    );
    const controlStyles = responsiveOffsetTop
      ? {
          marginTop: responsiveOffsetTop,
          transform: 'translateY(-50%)',
        }
      : {};
    const gutterOverflowValue = getResponsiveCssValue(
      screenSize,
      gutterOverflow,
    );
    const slideWidthValue = getResponsiveValue(screenSize, slideWidth);

    const nextControls = ({
      currentSlide,
      nextDisabled,
      nextSlide,
      slidesToScroll,
      slideCount,
    }: ControlProps) => {
      const allSlidesVisible = slideCount === slidesToScroll;
      const noSlidesLeft =
        currentSlide > 0 && currentSlide + slidesToScroll >= slideCount;

      if (noSlidesLeft || allSlidesVisible || nextDisabled) {
        return null;
      }

      return (
        <Box className={styles['control-right']} style={controlStyles}>
          <IconButton
            aria-label="Next slide"
            variant="knockout"
            onClick={nextSlide}
          >
            {isRebranded ? <IconChevronRight24 /> : <IconChevronRight16 />}
          </IconButton>
        </Box>
      );
    };

    const previousControls = ({
      previousDisabled,
      previousSlide,
    }: ControlProps) => {
      if (previousDisabled) {
        return null;
      }

      return (
        <Box className={styles['control-left']} style={controlStyles}>
          <IconButton
            aria-label="Previous slide"
            variant="knockout"
            onClick={previousSlide}
          >
            {isRebranded ? <IconChevronLeft24 /> : <IconChevronLeft16 />}
          </IconButton>
        </Box>
      );
    };

    const cellSpacing = (() => {
      if (isRebranded) {
        return medScreenOrSmaller ? 16 : 24;
      }

      return medScreenOrSmaller ? 8 : 16;
    })();

    return (
      <Box
        ref={ref}
        className={styles['carousel-container']}
        data-testid="carousel-container"
        mx={gutterOverflowValue && `-${gutterOverflowValue}`}
      >
        <NukaCarousel
          cellSpacing={cellSpacing}
          disableAnimation={prefersReducedMotion}
          disableEdgeSwiping
          dragging={!withoutControls}
          defaultControlsConfig={{ containerClassName: 'control-container' }}
          edgePadding={gutterOverflowValue}
          enableKeyboardControls
          frameAriaLabel={ariaLabel}
          renderBottomCenterControls={null}
          dragThreshold={0.15}
          renderCenterLeftControls={
            !responsiveOffsetTop ? previousControls : null
          }
          renderCenterRightControls={!responsiveOffsetTop ? nextControls : null}
          renderTopLeftControls={responsiveOffsetTop ? previousControls : null}
          renderTopRightControls={responsiveOffsetTop ? nextControls : null}
          slidesToScroll="auto"
          slideWidth={slideWidthValue}
          withoutControls={withoutControls}
        >
          {children}
        </NukaCarousel>
      </Box>
    );
  },
);

export default Carousel;
