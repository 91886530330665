import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconChevronRight16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M5.93687 2.09001L6.43288 2.58395L8.94716 5.08781L11.4614 7.59166L11.9596 8.08773L11.4614 8.58374L8.95412 11.0799L6.44687 13.576L5.9508 14.0699L4.96305 13.0778L5.45912 12.5839L7.96637 10.0877L9.97542 8.08759L7.95927 6.07981L5.44499 3.57596L4.94898 3.08201L5.93687 2.09001Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconChevronRight16);
export default ForwardRef;